import Keycloak from "keycloak-js";

import { getEnv } from "~/utils/polyfill";

import { version } from "../package.json";

console.info("Leviia Console", version);

const config = getEnv();

export const keycloak = new Keycloak({
    url: config.KC_URL,
    realm: config.KC_REALM,
    clientId: config.KC_CLIENT_ID
});

export const tokenDuration = 1800; // in seconds
export const currentAccessToken = ref("");

export function initKeycloak (): Promise<boolean> {
    // Refresh token every 58 seconds
    setInterval(updateToken, 58 * 1000);

    return keycloak.init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso",
        checkLoginIframeInterval: tokenDuration * 1000,
        checkLoginIframe: false
    });
}

function updateToken (): void {
    keycloak.updateToken(tokenDuration).then(refreshed => {
        if (refreshed) {
            currentAccessToken.value = keycloak.token as string;
        }
    }).catch(() => {
        console.error("Failed to refresh token");
    });
}

keycloak.onAuthSuccess = (): void => {
    currentAccessToken.value = keycloak.token as string;

    setInterval(updateToken, tokenDuration * 1000);
};
